import React from 'react';
import { t } from 'i18next';
import { Button, Table } from 'reactstrap';
import { TableHead, TableBody } from '../../../components';
import moment from 'moment';
import { connect } from 'react-redux';
import { ApiReservationService } from '../../../services/api/index';
import { reverse } from 'named-urls';
import routes from '../../../routing/routes';
import { ButtonIcon } from '../../../components/buttons';
import UncontrolledTooltip from '../../../components/reactstrap/UncontrolledTooltip';

class ReservationOverviewCompact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            date: props.match.params.date ? moment(props.match.params.date) : moment(),
            colSpan: 7,
            items: [],
            styles: {
                heading: { lineHeight: '2.5rem', padding: '0.5em 1em' },
                th: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#DCE7ED',
                    // padding: '0 1em'
                }
            }
        };
    }

    componentDidMount() {
        this.load();
    }

    render() {
        return (
            <Table bordered size='sm'>
                <TableHead>
                    <tr>
                        <th colSpan={7} style={this.state.styles.heading}>
                            {t('reservation:title.dated', { date: this.state.date.format('dddd, LL'), interpolation: { escapeValue: false } })}

                            <ButtonIcon className="float-right" i="overview" size="sm" onClick={() => this.props.history.push(reverse(routes.reservation.reservations.overview, { date: this.state.date.format('YYYY-MM-DD') }))}>
                                {t('reservation:view.full')}
                            </ButtonIcon>
                        </th>
                    </tr>
                </TableHead>
                {this.renderTable()}
            </Table>
        )
    }

    renderTable = () => {
        return (
            <TableBody loading={this.state.loading} cols={7} message={t('reservation:alert.no-result')}>
                {Object.keys(this.state.items).map(scheduleId => {
                    let { schedule, numbers, reservations } = this.state.items[scheduleId];
                    console.log(schedule, numbers, reservations)
                    return (
                        <React.Fragment key={schedule.id}>
                            <tr className='bg-gray-lighter'>
                                <th style={this.state.styles.th} colSpan={2}>{schedule.title}</th>
                                <th style={this.state.styles.th} colSpan={5} className="text-right">
                                    {t('reservation:heading.totals', { amount: numbers.persons - numbers.arrived, total: numbers.persons })}
                                </th>
                            </tr>

                            {Object.keys(reservations).map(hour => {
                                let hourData = reservations[hour];
                                if (hourData.reservations.length <= 0) return null;

                                return hourData.reservations.map(reservation => {
                                    if (!this.showReservation(reservation)) return null;

                                    let { id, title, remark, type, amount, status, menu, bookingDateFrom, bookingDateTill } = reservation;

                                    return (
                                        <tr key={id} className={status === 'arrived' ? 'bg-gray-lightest' : ''}>
                                            <td style={{ width: '10%' }} className="text-center">
                                                {moment(bookingDateFrom).format('HH:mm')}<br />
                                                <small className='text-muted'>{moment(bookingDateTill).format('HH:mm')}</small>
                                            </td>
                                            <td style={{ width: '25%' }}>{title}</td>
                                            <td style={{ width: '10%' }} className='text-center'>
                                                {type === 'group' ?
                                                    <><span className={'mr-1 fa fa-users'} data-tip={t('reservation:persons', { count: amount })}></span> {amount}</> :
                                                    <><span className={'mr-1 fa fa-user'} data-tip={t('reservation:persons', { count: amount })}></span> {amount}</>
                                                }
                                            </td>
                                            <td style={{ width: '20%' }} className='text-center'>{menu ? menu.title : ''}</td>
                                            <td style={{ width: '35%' }}>{remark}</td>
                                            <td className="table-actions">
                                                <Button id={'status-' + reservation.id} className="bg-gray-lightest" color="default" size="md" onClick={() => this.toggleStatus(reservation)}>
                                                    <span className='fa fa-check'></span>
                                                </Button>

                                                <UncontrolledTooltip target={'status-' + reservation.id} placement="top">
                                                    {t('reservation:statuses:arrived')}
                                                </UncontrolledTooltip>
                                            </td>
                                        </tr>
                                    );
                                })
                            })}
                        </React.Fragment>
                    );
                })}
            </TableBody>
        );
    }

    showReservation = (reservation) => true; // reservation.bookingDateFrom >= moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm');

    load = (showLoader = true) => this.setState({ loading: showLoader, item: showLoader ? [] : this.state.items }, () =>
        ApiReservationService.getByDate(this.state.date.format('YYYY-MM-DD'), this.props.group.id, this.props.location ? this.props.location.id : null, 'schedule-hour')
            .then(result => this.setState({ loading: false, items: result.reservations }))
    );

    toggleStatus = (reservation) =>
        ApiReservationService.quickUpdate(reservation.id, { update: 'status', value: reservation.status === 'arrived' ? null : 'arrived' })
            .then(() => this.load(false));
}

const mapStateToProps = state => ({ group: state.group, location: state.location });
export default connect(mapStateToProps)(ReservationOverviewCompact);